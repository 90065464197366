import React, { useEffect } from 'react';

import Helmet from 'react-helmet';
import { Container, Section } from '../components/common';
import { Header } from '../components/layout';
import { Navigation } from '../components/layout/Navigation';
import styled from 'styled-components';

const BookingSection = styled.div`
  button {
    font-size: 1rem !important;
  }
  h4 {
    font-size: 1.3rem !important;
    margin-top: 0.3rem !important;
  }
  section.booking-form {
    padding: 1.5rem 0 1rem !important;
  }
  ul.pct-offer-price {
    margin-left: 0 !important;
  }
`;
const book = () => {
  useEffect(() => {
    window._PCT = {
      dbname: 'pct_db',
      host: 'api.thepercentage.co',
      company_code: 'nomads_byron_bay',
      company_key: '104584314',
      show_booking_bar: true,
      maximum_nights: 14,
      ga: 'UA-34085064-1'
    };
    const script = document.createElement('script');
    script.src =
      'https://api.thepercentage.co/static/js/pct_booking_ui_v2_1_3.js';
    document.body.appendChild(script);
  }, []);
  return (
    <>
      <Helmet />
      <Section padding="1.5rem">
        <Navigation darkmenu />
      </Section>
      <Section>
        <BookingSection id="pct_booking_ui" />
      </Section>
    </>
  );
};
export default book;
